import Vue from 'vue';
import Vuetify from 'vuetify/lib';
Vue.use(Vuetify);
const LafiaPlugin = new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true,
      },

      //for light theme
      light: {
        primary: "#c49a28",
        primaryLight: "#ECBD44",
        primaryDark: "#57430E",
        secondaryLight: "#EDBE44",
        secondary: "#AE8B31",
        basic: "#e3b876",
        linkVisited: "#AE8B31",
        error: "#CC3030",
        warning: "#FF9931",
        black: "#000000",
        drawerLinkVisited: "#CAE1EE",
        basicLight: "#fae0ba",
        success: "#07C180",
      },

      //for dark theme
      dark: {},
    },
  },
});


export default LafiaPlugin;
