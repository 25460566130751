import axios from "axios";
import Links from "@/shared/constants/Links";

/**
 * This is the Payment API hook, for handling payments (C) 2021-2023
 */

const Payments = {
    /**
     *
     * @param {string} invoice the invoice reference number
     * @returns {Promise<object>}
     */
    processInline: async(invoice) => {
        return axios.post(Links.payments.processInlinePayment, {reference: invoice})
    },

    /**
     * initialize standalone payment transaction
     * @param {string} userId user id
     * @param {string} standalonePaymentId the standalone payment id
     * @returns {Promise<object>}
     */
    initializeStandalone: async(userId, standalonePaymentId) => {
        return axios.post(Links.payments.initializeStandalone, {
            standalonePayment: standalonePaymentId,
            userId: userId
        })
    },

    /**
     * download standalone invoice
     * @param {string} paymentId payment id
     * @returns {Promise<Blob>}
     */
    downloadStandaloneInvoice: async(paymentId) => {
        return axios.get(Links.payments.downloadStandaloneInvoice + "?payment=" + paymentId, {
            responseType: "blob"
        })
    },

    /**
     * waive invoice
     * @param {string} invoice invoice reference number
     * @returns {Promise<object>}
     */
    waiveInvoice: async(invoice) => {
        return axios.post(Links.payments.waiveInvoice, {reference: invoice})
    },

    /**
     * search for invoice
     * @param {string} invoice invoice reference number
     * @returns {Promise<object>}
     */
    searchInvoice: async(invoice) => {
        return axios.get(Links.payments.searchInvoice + "?invoice=" + invoice)
    },

    searchInvoiceStudent: async(invoice) => {
        return axios.get(Links.payments.searchInvoiceStudent, {
            params: {
                invoice
            }
        })
    },

    /**
     * @description Retrieves all students invoices
     * @param {string} studentId student id
     * @param {string} payment payment
     * @returns {Promise<AxiosResponse<any>>}
     */
    invoicesForStudent: async (studentId, payment) => {
        return axios.get(Links.payments.invoiceForStudent + `?student=${studentId}&payment=${payment}`)
    },


    /**
     * @param {string} invoice invoice id
     * @param {string} force to clear the invoice boolean
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteInvoice: async (invoice, force) => {
        return axios.post(Links.payments.deleteInvoice, {
            invoice: invoice,
            force: force
        })
    },

    /**
     * @description Moves standalone fee payment to another session
     * @param {string} invoice invoice id
     * @param {string} sessionId session id
     * @returns {Promise<AxiosResponse<any>>}
     */
    moveStandaloneInvoice: async(invoice, sessionId) => {
        return axios.post(Links.payments.moveStandaloneInvoice, {
            invoice: invoice,
            session: sessionId
        })
    },

    downloadProjection: async (session, semester, faculty) => {
        return axios.get(Links.payments.downloadProjection, {
            params: {
                session,
                semester,
                faculty
            },
            responseType: "blob"
        })
    }
}

export default Payments;
