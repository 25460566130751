<template>
  <div class="">
    <v-row >
      <v-col cols="12" md="12" v-if="!isMfa" class="d-flex justify-center">
        <v-card :elevation="isMobile ? '2' : '3'" :width="isMobile ? '350' : '450'" height="480"
          class="login-card d-flex flex-column mx-auto">
          <!-- Rest of the card content remains the same -->
          <v-card-text>
            <div class="my-6 mx-auto text-center logo-container">
              <v-img class="mx-auto logo-animation" contain width="100" height="100" :src="logo" />
            </div>
            <form @submit.prevent="login" class="login-form">
              <v-text-field 
                variant="outlined" 
                v-model="formData.username" 
                :readonly="loading" 
                color="primaryDark"
                density="comfortable" 
                placeholder="Username" 
                prepend-icon="mdi-account"
                class="input-field"
                hide-details
                :class="{'input-focused': formData.username}"
                required
              />
              <v-text-field 
                color="primaryDark" 
                :type="showPassword ? 'text' : 'password'" 
                v-model="formData.password" 
                density="comfortable" 
                variant="outlined" 
                :readonly="loading" 
                dense 
                placeholder="Password"
                prepend-icon="mdi-lock" 
                :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-inner="showPassword = !showPassword"
                class="input-field mt-4"
                hide-details
                :class="{'input-focused': formData.password}"
                required
              />

              <div class="pt-8 mx-auto">
                <v-btn 
                  elevation="2" 
                  type="submit" 
                  :disabled="!formData.username || !formData.password || loading"
                  color="primaryDark" 
                  width="100%" 
                  height="48" 
                  class="login-btn"
                >
                  <span class="white--text">Login</span>
                  <v-progress-circular
                    v-if="isLoading"
                    indeterminate
                    color="white"
                    size="20"
                    width="2"
                    class="ml-2"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </form>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col cols="12" md="12" class="py-5">
                <p class="text-center forgot-password" v-on:click="$emit('navigate', 1)">
                  Forgot Password?
                </p>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col v-else cols="12" md="12" class="d-flex justify-center">
        <v-card :elevation="isMobile ? '2' : '3'" :width="isMobile ? '350' : '450'" height="480"
                class="login-card">
          <!-- MFA card content remains the same -->
          <v-card-text>
            <div class="pa-4 mt-6" :class="{ 'q pa-2 rounded qb': isMobile }">
              <v-alert type="error" class="font-weight-bold my-8 error-alert">
                Verification required to proceed
              </v-alert>
              <div class="mb-4">
                <v-alert type="error" dismissible dense class="mb-8 error-alert" v-show="hasError">{{ errorMsg }}</v-alert>
                <v-alert type="success" dismissible dense class="mb-8 success-alert" v-show="!hasError && mfaSent">{{ mfaMsg }}
                </v-alert>
                <div  class="otp-container">
                  <v-otp-input
                      length="6"
                      v-model="mfas"
                      :disabled="isLoading"
                      v-if="!usingRecovery"
                      class="otp-input"
                  />
                  <v-text-field 
                  v-else
                    height="54px" 
                    dense 
                    variant="outlined" 
                    density="comfortable" 
                    placeholder="Provide one of your recovery codes" 
                    class="mb-0 input-field" 
                    v-model="mfa.recovery"
                  />
                </div>
                <div class="d-flex justify-end">
                  <v-switch class="pa-0 ma-0 recovery-switch" v-model="usingRecovery" :true-value="true" :false-value="false" label="Use recovery"></v-switch>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12" md="12" class="py-5 px-10">
                <v-btn 
                  elevation="2" 
                  :loading="isLoading" 
                  type="submit" 
                  :disabled="(usingRecovery ? !mfa.recovery : mfas.length !== 6) || loading"
                  color="primaryDark" 
                  width="100%" 
                  height="48" 
                  @click="verifyCode"
                  class="login-btn"
                >
                  <span class="white--text">Proceed</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <loading-dialog :show="isLoading"></loading-dialog>
    <message-box :show="messageShow" :message="message" :type="messageType"
      v-on:close-message="messageShow = !messageShow"></message-box>
    <confirmation-box :message="newRegMessage" positive-text="Ok" negative-text=" " title="Info"
                      :show="usingOldReg" v-on:positive="usingOldReg = false" v-on:negative="usingOldReg = false">
    </confirmation-box>
  </div>
</template>

<script>
import LoadingDialog from "ui/lafia/components/LoadingDialog.vue";
import MessageBox from "@ui/components/MessageBox.vue";
import Api from "main/api";
import LoginUtil from "../LoginUtil";
import Roles from "shared/Roles";
import ErrorResolver from "shared/ErrorResolver";
import ConfirmationBox from "components/ConfirmationBox.vue";

export default {
  name: 'Login',
  props: ["logo"],
  components: { ConfirmationBox, LoadingDialog, MessageBox },
  data: () => ({
    isLoading: false,
    loading: false,
    hasError: false,
    errorMsg: "",
    mfaMsg: "",
    mfaSent: false,
    messageType: '',
    message: '',
    messageShow: false,
    usingOldReg: false,
    newRegMessage: '',
    authMode: 0,
    reader: null,
    deviceConnected: false,
    showForgotPassword: false,
    showPassword: false,
    stateGlobal: {},
    formData: {
      username: "",
      password: ""
    },
    mfa: {
      userId: '',
      code: '',
      recovery: null
    },
    mfas: '',
    mfaLength: 6,
    usingRecovery: false,
    isMfa: false,
  }),

  methods: {
    login() {
      if (this.formData.password === undefined || this.formData.password === '' || this.formData.username === undefined || this.formData.username === '')
        return
      this.isLoading = true;
      this.usingOldReg = false;

      Api.auth.login(this.formData).then(res => {
        this.hasError = false;
        if(res.data.old){
          if (res.data.applicant) {
            this.usingOldReg = true;
            this.newRegMessage = "Use your new ID: (" + res.data.applicant + ") to login";
          } else if (res.data.message){
            this.usingOldReg = true;
            this.newRegMessage = "Use the username: " + res.data.message + " as you new username";
          } else if (res.data.userId) {
            this.usingOldReg = true;
            this.newRegMessage = "Login using the following ID: " + res.data.userId + '. Password remains same as your applicant password'
          }
        }
        else if (res.data.mfa) {
          this.isMfa = true
          this.mfa.userId = res.data.userId
        } else {
          LoginUtil.setAccessToken(res.data.jwt);
          LoginUtil.setRefreshToken(res.data.refreshToken);
          if (Roles.contains(res.data.roles, Roles.Admin) || Roles.contains(res.data.roles, Roles.SuperAdmin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student) || Roles.contains(res.data.roles, Roles.Applicant)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }
      }).catch(err => {
        this.hasError = true
        ErrorResolver.checkErrors(this, err)
      }).finally(() => {
        this.isLoading = false;
      })
    },

    verifyCode() {
      this.isLoading = true
      this.mfa.code = this.usingRecovery ? this.mfa.recovery : this.mfas
      Api.auth.verifyMfa(this.mfa, this.usingRecovery)
        .then(res => {
          LoginUtil.setAccessToken(res.data.jwt)
          LoginUtil.setRefreshToken(res.data.refreshToken);

          if (Roles.contains(res.data.roles, Roles.Admin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }).catch(err => {
          ErrorResolver.checkErrors(this, err)
        }).finally(() => {
          this.isLoading = false
        })
    },

    fetchGlobal() {
      Api.global.fetch().then((res) => {
        this.stateGlobal = res.data;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
        .finally(() => this.loading = false)
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
}
</script>

<style lang="scss" scoped>
.login-card {
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(87, 67, 14, 0.15);
  border-radius: 16px;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 24px rgba(87, 67, 14, 0.12) !important;
  }
}

.logo-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}

.logo-animation {
  animation: fadeInScale 0.8s ease-out;
}

.login-form {
  padding: 0 1rem;
}

.input-field {
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  
  :deep(.v-field) {
    border-radius: 12px !important;
    background: rgba(255, 255, 255, 0.9);
    border: 1.5px solid rgba(87, 67, 14, 0.2);
    transition: all 0.3s ease;

    &:hover {
      border-color: rgba(87, 67, 14, 0.4);
      background: rgba(255, 255, 255, 0.95);
    }
  }

  &.input-focused {
    :deep(.v-field) {
      border-color: #57430E;
      background: white;
      box-shadow: 0 2px 8px rgba(87, 67, 14, 0.08);
    }
  }
}

.login-btn {
  border-radius: 12px;
  text-transform: none;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 1.1em;
  transition: all 0.3s ease;
  background: linear-gradient(45deg, #57430E, #AE8B31) !important;
  position: relative;
  overflow: hidden;

  &:not(:disabled) {
    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(87, 67, 14, 0.25) !important;
      
      &::after {
        opacity: 1;
      }
    }

    &:active {
      transform: translateY(0);
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &:disabled {
    opacity: 0.7;
    background: linear-gradient(45deg, #8B7340, #C4A86B) !important;
  }
}

.forgot-password {
  color: #57430E;
  opacity: 0.8;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.95em;
  text-decoration: none;
  margin-top: 1rem;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}

.error-alert, .success-alert {
  border-radius: 12px;
  font-size: 0.95em;
  margin-bottom: 1.5rem;
}

.otp-container {
  margin: 2em 0;
}

.otp-input {
  :deep(.v-otp-input) {
    gap: 8px;
  }

  :deep(.v-field) {
    border-radius: 12px !important;
    background: rgba(255, 255, 255, 0.9);
    border: 1.5px solid rgba(87, 67, 14, 0.2);
    transition: all 0.3s ease;

    &:focus {
      border-color: #57430E;
      background: white;
      box-shadow: 0 2px 8px rgba(87, 67, 14, 0.08);
    }
  }
}

.recovery-input {
  :deep(.v-field) {
    border-radius: 12px !important;
    background: rgba(255, 255, 255, 0.9);
    border: 1.5px solid rgba(87, 67, 14, 0.2);
    transition: all 0.3s ease;

    &:focus {
      border-color: #57430E;
      background: white;
      box-shadow: 0 2px 8px rgba(87, 67, 14, 0.08);
    }
  }
}

.recovery-switch {
  :deep(.v-switch) {
    opacity: 0.9;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
