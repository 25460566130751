import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the Results API hook, for handling all the system results in the system (C) 2021-2023
 */



const Results = {
    /**
     * handles creation of result without depending on other selection
     * @param {object} data the information of the result to be created
     * @returns {Promise<object>}
     */
    create: async (data) => {
        return axios.post(Links.results.create, data)
    },

    /**
     * Updating of a single result
     * @param {object} data the information of the result to be updated
     * @returns {Promise<object>}
     */
    updateSingle: async (data) => {
        return axios.put(Links.results.updateSingleResult, data)
    },

    /**
     * Delete a single result
     * @param {object} data
     * @returns {Promise<object>}
     */
    deleteResult: async(data) => {
        return axios.post(Links.results.deleteResult , data)
    },

    /**
     * Fetch all results depending on the selected session, semester, programmeId, page and itemPerPage
     * @param {string} sessionId the selected session id
     * @param {string} semesterId the selected semester id
     * @param {string} courseId the selected course id
     * @param {string} programmeId the selected programmeId
     * @param {number} level the selected level id
     * @param {number} page the page size e.g 0
     * @param {number} itemsPerPage the length of the content to be displayed default of 10
     * @returns {Promise<any[]>}
     */
    fetchAllForCourseForSemester: async (sessionId, semesterId, courseId, page, itemsPerPage, programmeId, level) => {
        return axios.get(Links.results.getAllForCourseForSemester + "?sessionId=" + sessionId + '&semesterId=' + semesterId + '&courseId=' + courseId + '&page=' + page + '&size=' + itemsPerPage + (programmeId ? ("&programme="+programmeId) : "") + (level ? ("&level="+level) : ""))
    },

    /**
     * Download all courses for a semester depending on the selected session, semester and course (.xlsx)
     * @param {number} semesterId the selected semester id
     * @param {number} courseId the selected course id
     * @returns {Promise<Blob>}
     */
    downloadAllForCourseForSemester: async (courseId, semesterId) => {
        return axios.get(`${Links.results.downloadAllForCourseForSemester}?course=${courseId}&semester=${semesterId}`, {
            responseType: 'blob'
        })
    },

    /**
     * Download academic report depending on the selected programme, semester and level
     * @param {string} programme the selected programme id
     * @param {string} semesterId the selected semester id
     * @param {string} level the selected level id
     * @param type
     * @param rangeBegin
     * @param rangeEnd
     * @param numberOfStudents
     * @param students
     * @returns {Promise<Blob>}
     */
    downloadAcademicReport: async (programme, semesterId, level, type, rangeBegin, rangeEnd, numberOfStudents, students) => {
        let params = `?semester=${semesterId}&level=${level}&programme=${programme}`;
        if(type !== null && type !== undefined){
            params = params + '&report_type=' + type
            if(type === 1){
                params = params + '&number_of_reports=' + numberOfStudents
            } else if(type === 2){
                params = params + '&range_start=' + rangeBegin
                params = params + '&range_end=' + rangeEnd
            } else if(type === 3){
                // reduce array and separate with commas
                let s = students.reduce((previousValue, currentValue) => previousValue + "," + currentValue, "")
                params = params + '&students=' + s
            }
        }
        return axios.get(Links.results.downloadReport + params, {
            responseType: 'blob'
        })
    },

    /**
     * Download graduation report depending on the selected programme, semester and level
     * @param {string} programme the selected programme id
     * @param {string} semesterId the selected semester id
     * @returns {Promise<Blob>}
     */
    downloadGraduationReport: async (programme, semesterId) => {
        return axios.get(Links.results.downloadGraduationReport + "?programme=" + programme + '&semester=' + semesterId, {
            responseType: 'blob'
        })
    },

    /**
     * Download graduation report depending on the selected programme, semester and level
     * @param {string} programme the selected programme id
     * @param {string} semesterId the selected semester id
     * @returns {Promise<Blob>}
     */
    downloadGraduationReportCsV: async (programme, semesterId) => {
        return axios.get(Links.results.downloadGraduationReportCsv, {
            responseType: 'blob',
            params: {
                programme: programme,
                semester: semesterId
            }
        })
    },

    /**
     * Fetch course report depending on the sesion, semester and courseId
     * @param {string} sessionId the selected session id
     * @param {string} semesterId the selected semester id
     * @param {string} courseId the selected course id
     * @returns {Promise<any[]>}
     */
    getReportForCourse: async (sessionId, semesterId, courseId) => {
        return axios.get(Links.results.getReportFor + sessionId + '/' + semesterId + '/' + courseId)
    },

    /**
     * Upload result in bulk (.xlsx)
     * @param {object} data the data needed to be uploaded
     * @param {string} sessionId the selected session id
     * @param {string} semesterId the selected semester id
     * @param {string} courseId the selected course id
     * @returns {Promise<Blob>}
     */
    uploadResult: async (data, sessionId, semesterId, courseId) => {
        return axios.post(Links.results.uploadResult + sessionId + '/' + semesterId + '/' + courseId, data)
    },

    /**
     * Moderation happens due to poor performance, we have two type of moderation i.e linear and flat moderation. In this situation, scores are being added to boost the student grades
     * @param {object} data required information
     * @param {string} sessionId the selected session id
     * @param {string} semesterId the selected semester id
     * @param {string} courseId the selected course id
     * @returns {Promise<object>}
     */
    moderate: async (data, sessionId, semesterId, courseId) => {
        return axios.put(Links.results.moderate + sessionId + '/' + semesterId + '/' + courseId, data)
    },

    /**
     * Submit a course to be viewed by other users in the system; students, lecturers, dean, DVCAcademics
     * @param {object} data
     * @returns {Promise<object>}
     */
    submitCourse: async (data) => {
        return axios.post(Links.results.submitCourse, data);
    },

    /**
     * Fetch reports for programmes in the department depending on the selected programme, semester and level
     * @param {string} programme the selected programme id
     * @param {string} semester the selected semester id
     * @param {string} level the selected level id
     * @returns {Promise<any[]>}
     */
    reportForLevelProgrammeDepartment: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelDep + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    /**
     * Fetch result report forprogrammes in the faculty depending on programme, semester and level
     * @param {string} programme the selected programme id
     * @param {string} semester the selected semester id
     * @param {string} level the selected level id
     * @returns {Promise<any[]>}
     */
    reportForLevelProgrammeFaculty: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelFac + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    /**
     * Fetch result report of programme levels for the senate submitte
     * @note the senatecommitte handles result approval before moving to the senate for approval
     * @param {string} programme the selected programme id
     * @param {string} semester the selected semester id
     * @param {string} level the selected level id
     * @returns {Promise<any[]>}
     */
    reportForLevelProgrammeSubCommittee: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelSub + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    /**
     * Fetch result report for senate
     * @param {string} programme the selected programme id
     * @param {string} semester the selected semester id
     * @param {string} level the selected level id
     * @returns {Promise<any[]>}
     */
    reportForLevelProgrammeSenate: async (programme, semester, level) => {
        return axios.get(Links.results.getReportForProgrammeLevelSen + '?programme='+ programme+ '&level=' + level + '&semester=' + semester )
    },

    /**
     * Fetch result report of levels in a department
     * @param {string} department the selected department id
     * @param {string} session the selected session id
     * @param {string} semester the selected semester id
     * @param {string} level the selected level id
     * @param {string} senate the selected senate id
     * @returns {Promise<any[]>}
     */
    reportForLevelDepartment: async (department, session, semester, level, senate) => {
        return axios.get(Links.results.getReportForDepartmentLevel + department + '/' + level + '/' + session + '/' + semester + (senate ? '?senate=1' : ''))
    },

    /**
     * Fetch result report of departments in the school
     * @param {string} department the selected department id
     * @param {string} session the selected session id
     * @param {string} semester the selected semester id
     * @param {string} senate the selected senate id
     * @returns {Promise<any[]>}
     */
    reportForDepartment: async (department, session, semester, senate) => {
        return axios.get(Links.results.getReportForDepartment + department + '/' + session + '/' + semester + (senate ? '?senate=1' : ''))
    },

    /**
     * Fetch result report of faculties in the school depending on the selected faculty, session and semester
     * @param {string} faculty the selected faculty id
     * @param {string} session the selected session id
     * @param {string} semester the selected semester id
     * @returns {Promise<any[]>}
     */
    reportForFaculty: async (faculty, session, semester) => {
        return axios.get(Links.results.getReportForFaculty + faculty + '/' + session + '/' + semester )
    },


    /**
     * Approve a course for students. Course, session and semester needs to be selected
     * @param {string} courseId the selected course id
     * @param {string} sessionId the selected session id
     * @param {string} semester the selected semester id
     * @returns {Promise<object>}
     */
    approvalCourseAtDept: async (courseId, sessionId, semester) => {
        return axios.put(Links.results.approvalCourseAtDept + sessionId + '/' + semester + '/' + courseId )
    },

    /**
     * Disapprove(Reject) a course for student. Course, session and semester needs to be selected
     * @param {string} courseId the selected course id
     * @param {string} sessionId the selected session id
     * @param {string} semester the selected semester id
     * @returns {Promise<any[]>}
     */
    disApprovalCourseAtDept: async (courseId, sessionId, semester) => {
        return axios.put(Links.results.disApprovalCourseAtDept + sessionId + '/' + semester + '/' + courseId )
    },

    /**
     * Approve result at faculty level. Programme, level and semester needs to be selected
     * @param {string} programme the selected programme id
     * @param {string} level the selected level id
     * @param {string} semester the selected semester id
     * @returns {Promise<object>}
     */
    approvalResultsFaculty: async (programme, level, semester) => {
        return axios.put(Links.results.approvalResultsFaculty + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },

    /**
     * Disapprove(Reject) result at faculty level. Programme, level and semester needs to be selected
     * @param {string} programme the selected programme id
     * @param {string} level the selected level id
     * @param {string} semester the selected semester id
     * @returns {Promise<object>}
     */
    disApprovalResultsFaculty: async (programme, level, semester) => {
        return axios.put(Links.results.disApprovalResultsFaculty + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },

    // subcommittee
    /**
     * Approve result by sub-senate-commmitte. Programme, level and semester needs to be selected
     * @param {string} programme the selected programme id
     * @param {string} level the selected level id
     * @param {string} semester the selected semester id
     * @returns {Promise<object>}
     */
    approvalResultsSubCommittee: async (programme, level, semester) => {
        return axios.put(Links.results.approvalResultsSubCommittee + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },

    /**
     * Disapprove(Reject) result by sub-senate-committe. Programme, level and semester needs to be selected
     * @param {string} programme the selected programme id
     * @param {string} level the selected level id
     * @param {string} semester the selected semester id
     * @returns {Promise<any[]>}
     */
    disApprovalResultsSubCommittee: async (programme, level, semester) => {
        return axios.put(Links.results.disapprovalResultsSubCommittee + '?programme=' + programme + '&semester=' + semester + '&level=' + level )
    },

    // senate
    /**
     * Approve result by senate. Programme, semester and level needs to be selected
     * @param {string} programme the selected programme id
     * @param {string} semester the selected semester id
     * @param {string} level the selected level id
     * @returns {Promise<object>}
     */
    approvalResultsSenate: async (programme, semester, level) => {
        return axios.put(Links.results.approvalResultsSenate + '?programme=' + programme + '&level=' + level + '&semester=' + semester )
    },

    /**
     * Disapprove(Reject) result by senate. Programme, semester and level needs to be selected
     * @param {string} programme the selected programme id
     * @param {string} semester the selected semester id
     * @param {string} level the selected level id
     * @returns {Promise<object>}
     */
    disApprovalResultsSenate: async (programme, semester, level) => {
        return axios.put(Links.results.disApprovalResultsSenate + '?programme=' + programme + '&level=' + level + '&semester=' + semester )
    },

    /**
     * Fetch a specific student result, student userid, session and semester must be selected.
     * @param {string} student the student userId(matric)
     * @param {string} session the selected session id
     * @param {string} semester the selected semester id
     * @returns
     */
    forStudent: async (student, session, semester) => {
        return axios.get(Links.results.forStudent + "?student=" + student + '&session=' + session + '&semester=' + semester);
    },

    /**
     * Update student score sheet, semester must be selected. @note only accessible by the admin of this system
     * @param {object} data the information to be updated(scores)
     * @param {string} semesterId the selected semester id
     * @returns {Promise<object>}
     */
    uploadScoreSheetAdmin: async (data, semesterId) => {
        return axios.post(Links.results.uploadSheetAdmin + '/' + semesterId , data)
    },

    /**
     * Approve all results, at this stage the result has passed all required protocol set by the school, Session and semester must be selected.
     * @param {string} sessionId the selected session id
     * @param {string} semesterId the selected semester id
     * @returns {Promise<object>}
     */
    approveAllResults: async (sessionId, semesterId) => {
        return axios.post(Links.results.approveAllResults, {
            session: sessionId,
            semester: semesterId
        })
    },

    /**
     * Swipe all result from the system, we assume there should be a backup for this at the database level. Semester, programme, course and deleteRegistration date must be selected
     * @param {string} semester the selected semester id
     * @param {string} programme the selected programme id
     * @param {string} course the selected course id
     * @param {number} level the selected level id
     * @param {string} deleteRegistrations
     * @returns {Promise<object>}
     */
    deleteAllResults(semester, programme, course, level, deleteRegistrations){
        return axios.delete(Links.results.deleteAllResult + "?programmeId=" + programme + "&semesterId=" + semester + "&courseId=" + course + "&deregister=" + deleteRegistrations + "&levelId=" + level)
    },

    /**
     *
     * @param {string} studentId
     * @param {number} programmeType
     * @param {number} semester
     * @param {number} course
     * @returns {Promise<AxiosResponse<any>>}
     */
    searchResult: async(studentId, programmeType, semester, course) => {
        return axios.get(Links.results.searchResult + "?programme_type=" + programmeType + "&student=" + studentId + "&semester=" + semester + "&course=" + course)
    },

    /**
     * @description Deletes all results for a student for a given semester
     * @param {number} semesterId
     * @param {string} studentMatric
     * @param {Boolean} removeRegistration
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteStudentSemesterResults: async (semesterId, studentMatric, removeRegistration) => {
        return axios.post(Links.results.deleteStudentSemesterResults, {
            semester: semesterId,
            student: studentMatric,
            removeRegistrations: removeRegistration
        })
    },

    resultsAdministrative: async(student, semester) => {
        return axios.get(Links.results.studentsAdmin + `?student=${student}&semester=${semester}`)
    }
}

export default Results
