import Vue from 'vue'
import lafiaApplication from "./pages/lafia/ApplicationV2.vue";
import store from './store'
import lafiaRouter from './routes/other';
import 'vue-toast-notification/dist/theme-sugar.css';
import Defaults from '@/shared/Defaults';
import axios from 'axios';
import AppTypes from "../shared/constants/apptypes";
import Toast from "vue-toastification";
import NotificationPlugin from '../plugins/notify';
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toastification/dist/index.css";
import vuetifyLafia from "../plugins/lafiaPlugin";
import vuetifyFpn from "../plugins/fpnPlugin";



if(window.API){
    // set default api
    Defaults.api = window.API
    // set up axios api
    axios.defaults.baseURL = window.API
} else{
    axios.defaults.baseURL = Defaults.api // use api for test
}

let lafia = AppTypes.CURRENT === AppTypes.LAFIA

Vue.config.productionTip = false
Vue.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true
})  
Vue.use(NotificationPlugin)
new Vue({
    store,
    vuetify: lafia ? vuetifyLafia : vuetifyFpn,
    router: lafiaRouter ,
    render: h => h(lafiaApplication)
}).$mount('#app')
