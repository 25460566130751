import axios from "axios";
import Links from "../constants/Links";


/**
 * This is the Hostel API hook, for handling Hostel (C) 2021-2023
 */

const Hostel = {
    /**
     * @description return hostel config. object contain fee, privileged levels
     * @returns {Promise<object>}
     */
    getSettings: async (session) => {
        return axios.get(Links.hostel.getSettings + "?session=" + session)
    },

    /**
     * update hostel fee
     * @param {object} data fee to be updated
     * @returns {Promise<object>}
     */
    updateConfig: async (data) => {
        return axios.post(Links.hostel.updateConfig, data)
    },

    /**
     * create an hostel block
     * @param {object} data block to be created
     * @returns {Promise<object>}
     */
    createBlock: async (data) => {
        return axios.post(Links.hostel.createBlock, data)
    },

    updateBlock: async (id,data) => {
        return axios.post(Links.hostel.updateBlock + "/" + id, data)
    },


    /**
     * fetch all hostel block base on gender selected
     * @param {object} gender
     * @returns {Promise<object>}
     */
    allBlocks: async (gender) => {
        return axios.get(Links.hostel.allBlocks + (gender ? '?gender=' + gender : ''))
    },

    /**
     * fetch all blocks base on gender (paginated)
     * @param {string} gender gender to be selected
     * @param {string} page
     * @param {string} size e.g 10
     * @returns
     */
    allBlocksPaginate: async (gender, page, size) => {
        return axios.get(Links.hostel.allBlocksPaginate + '?page=' + page + '&size=' + size + (gender ? '&gender=' + gender : ''))
    },

    /**
     * create hostel block inside a block
     * @param {object} data room to be created
     * @returns {Promise<object>}
     */
    createRoom: async (data) => {
        return axios.post(Links.hostel.createRoom, data)
    },

    updateRoom: async (id,data) => {
        return axios.post(Links.hostel.updateRoom + "/" + id, data)
    },

    /**
     * fetch all rooms depending on a block
     * @param {object} floor the block to be selected
     * @returns {Promise<any[]>}
     */
    allRooms: async (floor) => {
        return axios.get(Links.hostel.allRooms + (floor ? '?floor=' + floor : ''))
    },

    /**
     * fetch all rooms in the hostel
     * @param {string} block id of the block
     * @param {string} page default e.g 0
     * @param {string} size default e.g 10
     * @returns {Promise<any[]>}
     */
    allRoomsPaginated: async (floor, page, size) => {
        return axios.get(Links.hostel.allRoomsPaginated + '?page=' + page + '&size=' + size + (floor ? '&floor=' + floor : ''))
    },

    /**
     * create a room space inside a block
     * @param {object} data
     * @returns {Promise<object>}
     */
    createRoomSpace: async (data) => {
        return axios.post(Links.hostel.createRoomSpace, data)
    },

    updateRoomSpace: async (id,data) => {
        return axios.post(Links.hostel.updateRoomSpace + "/" + id, data)
    },

    /**
     * fetch all room space inside a block in a hostel
     * @param {string} room id of the room
     * @param {string} page default e.g 0
     * @param {string} size default e.g 10
     * @returns
     */
    allRoomSpaces: async (room, page, size) => {
        return axios.get(Links.hostel.allRoomSpacesPaginated + '?page=' + page + '&size=' + size + (room ? '&room=' + room : ''))
    },

    spaces: async (room) => {
        return axios.get(Links.hostel.allRoomSpaces + '?room=' + room)
    },

    /**
     *
     * @param interest
     * @param floor
     * @returns {Promise<object>}
     */
    roomsForSelection: async (interest, floor) => {
        return axios.get(Links.hostel.allRoomsForSelection, {
            params: {
                interest, floor
            }
        })
    },

    /**
     * allocate room space to user
     * @param {string} space roomspace id
     * @param {object} data information of the user(to be allocated to)
     * @returns {Promise<object>}
     */
    allocateRoom: async (space, data) => {
        return axios.put(Links.hostel.allocateSpace + '/' + space, data)
    },

    /**
     * revoke all allocations
     * @returns {Promise<object>}
     */
    revokeAllocations: async () => {
        return axios.put(Links.hostel.revokeAllocations)
    },

    /**
     * @description returns all available spaces for student
     * @param {string} studentId the student ID
     * @returns {Promise<AxiosResponse<any>>} containing the room space
     */
    getAvailableSpaces: async (studentId) => {
        return axios.get(Links.hostel.getAvailableSpaces + '?student=' + studentId)
    },

    /**
     * @description Initializes hostel payment transaction
     * @param {object} data the object containing the following properties: studentId and sessionId. the former is the matric no of the student while the latter is the session's DB id
     * @returns {Promise<AxiosResponse<any>>} containing payment object
     */
    initializeTransaction: async (data) => {
        return axios.post(Links.hostel.initializeTransaction, data)
    },

    /**
     * @description Verifies hostel payment with the given reference
     * @param {string} reference the transaction reference
     * @returns {Promise<AxiosResponse<any>>} containing payment object
     */
    verifyPayment: async (reference) => {
        return axios.put(Links.hostel.verifyPayment + '/' + reference)
    },

    /**
     * @param {object} data the object containing the following properties: studentId and sessionId. the former is the matric no of the student while the latter is the session's DB id
     * @returns {Promise<object>} containing the room space
     */
    selectSpace: async (data) => {
        return axios.post(Links.hostel.selectSpace, data)
    },

    /**
     * @description Returns student's hostel information for a session
     * @param {string} studentId the userID (matric) of the student
     * @param {string} sessionId the session's DB id
     * @returns {Promise<AxiosResponse<any>>} an object contaning payment information, eligibility and room space information.
     */
    getStudentInformation: async (studentId, sessionId) => {
        return axios.get(Links.hostel.getStudentInformation + '?student=' + studentId + '&session=' + sessionId)
    },

    /**
     * @description Adds a single level to privileged levels
     * @param {object} data object containing the level's id to be added. property is levelId
     * @returns {Promise<AxiosResponse<any>>} contains hostel config
     */
    addLevel: async (data) => {
        return axios.post(Links.hostel.addLevel, data)
    },

    /**
     * @description Removes a single level from privileged levels
     * @param {object} data object containing the level's id to be added. property is levelId
     * @returns {Promise<AxiosResponse<any>>} contains hostel config
     */
    removeLevel: async (data) => {
        return axios.post(Links.hostel.removeLevel, data)
    },

    /**
     * generate invoice for the hostel payment
     * @param {string} invoice invoice number
     * @returns {Promise<Blob>}
     */
    invoice: async(invoice) => {
        return axios.get(Links.hostel.invoice + '?invoice=' + invoice, {
            responseType: 'blob'
        })
    },

    /**
     * Upload hostel reservation in bulk (.xlsx)
     * @param {Blob} data the data needed to be uploaded
     * @returns {Promise<Blob>}
     */
    reservation: async (data) => {
        return axios.post(Links.hostel.uploadReservations, data)
    },

    /**
     * download hostel allocation report for a session
     * @param {number} sessionId
     * @returns {Promise<Blob>}
     */
    downloadReport: async (sessionId) => {
        return axios.get(`${Links.hostel.downloadReport}?session=${sessionId}`, {
            responseType: 'blob'
        })
    },
    downloadReservationReport: async (sessionId) => {
        return axios.get(`${Links.hostel.downloadReservationReport}?session=${sessionId}`, {
            responseType: 'blob'
        })
    },

    downloadPendingAllocationReport: async (sessionId) => {
        return axios.get(`${Links.hostel.downloadPendingAlocationReport}?session=${sessionId}`, {
            responseType: 'blob'
        })
    },

    getReport: async(sessionId, page, size) => {
        return axios.get(`${Links.hostel.getReport}?session=${sessionId}&page=${page}&size=${size}`)
    },

    blockCriteria: async (blockId)=> {
        return axios.get(Links.hostel.blockCriteria + `?block=${blockId}`)
    },

    roomCriteria: async (roomId)=> {
        return axios.get(Links.hostel.roomCriteria + `?room=${roomId}`)
    },

    updateBlockCriteria: async (data)=> {
        return axios.post(Links.hostel.addBlockCriteria, data)
    },

    floorCriteria: async (floorId)=> {
        return axios.get(Links.hostel.floorCriteria + `?floor=${floorId}`)
    },

    updateFloorCriteria: async (data)=> {
        return axios.post(Links.hostel.floorCriteria, data)
    },

    updateRoomCriteria: async (data)=> {
        return axios.post(Links.hostel.addRoomCriteria, data)
    },

    updateBlockPayment: async (data) => {
        return axios.post(Links.hostel.blockPayment, data)
    },

    getBlockPayments: async (session) => {
        return axios.get(Links.hostel.blockPayment + '?session=' + session)
    },

    privilegesTypes: async () => {
        return axios.get(Links.hostel.privilegesTypes)
    },

    createPrivilege: async (data) => {
        return axios.post(Links.hostel.privileges, data)
    },

    updatePrivilege: async (id, data) => {
        return axios.put(Links.hostel.privileges + "/" + id, data)
    },

    privileges: async () => {
        return axios.get((Links.hostel.privileges))
    },

    positions: async () => {
        return axios.get((Links.hostel.positions))
    },

    createPosition: async (data) => {
        return axios.post(Links.hostel.positions, data)
    },

    updatePosition: async (id, data) => {
        return axios.put(Links.hostel.positions + "/" + id, data)
    },

    addStudentPrivilege: async (data) => {
        return axios.post(Links.hostel.studentPrivileges, data)
    },

    removeStudentPrivilege: async (id) => {
        return axios.delete(Links.hostel.studentPrivileges + "/" + id)
    },

    studentPrivileges: async (session, page, size) => {
        return axios.get((Links.hostel.studentPrivileges + `?page=${page}&size=${size}&session=${session}`))
    },

    addAllowedStudent: async (data) => {
        return axios.post(Links.hostel.allowedStudent, data)
    },

    removeAllowedStudent: async (id) => {
        return axios.delete(Links.hostel.allowedStudent + "/" + id)
    },

    allowedStudents: async (session, page, size) => {
        return axios.get((Links.hostel.allowedStudent + `?page=${page}&size=${size}&session=${session}`))
    },

    createFloor: async (data) => {
        return axios.post(Links.hostel.floors, data)
    },

    updateFloor: async (id, data) => {
        return axios.put(Links.hostel.floors + "/" + id, data)
    },

    deleteFloor: async (id) => {
        return axios.delete(Links.hostel.floors + "/" + id)
    },

    floors: async (block) => {
        return axios.get(Links.hostel.floors + "?block=" + block)
    },

    getReservations: async (session, page, size) => {
        return axios.get(Links.hostel.reservations + "?session=" + session + "&page=" + page + "&size=" + size)
    },

    createReservation: async (data) => {
        return axios.post(Links.hostel.reservations, data)
    },

    cancelReservation: async  (id) => {
        return axios.post(Links.hostel.reservations + "/free/" + id)
    },

    initializeInterestFee: async (student, session) => {
        return axios.post(Links.hostel.initializeInterestFee, {
            studentId: student,
            sessionId: session
        })
    },
    initializeHostelFee: async (student, session) => {
        return axios.post(Links.hostel.initializeHostelFee, {
            studentId: student,
            sessionId: session
        })
    },

    initializeMaintenanceFee: async (student, session) => {
        return axios.post(Links.hostel.initializeMaintenanceFee, {
            studentId: student,
            sessionId: session
        })
    },

    studentAllocationReport: async (allocation) => {
        return axios.get(Links.hostel.studentllocationReport, {
            params: {
                allocation
            },
            responseType: 'blob'
        })
    },

    allocations: async (params) => {
        return axios.get(Links.hostel.allocations, {
            params: params
        })
    },

    pendingAllocations: async (params) => {
        return axios.get(Links.hostel.pendingAllocations, {
            params: params
        })
    },

    confirmAllocation: async (allocation) => {
        return axios.post(Links.hostel.confirmllocations + "/" + allocation)
    },

    confirmAllAllocation: async (session) => {
        return axios.post(Links.hostel.confirmAllAllocations , {
            session
        })
    },

    processAllAllocation: async (session, privilege) => {
        return axios.post(Links.hostel.processAllAllocations , {
            session, privilege
        })
    },

    rejectAllocation: async (allocation) => {
        return axios.post(Links.hostel.rejectAllocations + "/" + allocation)
    },

    interests: async (params) => {
        return axios.get(Links.hostel.interests, {
            params: params
        })
    },

    reportdData: async (session) => {
        return axios.get(Links.hostel.reportsData, {
            params: {
                session
            }
        })
    },

    getRoles: async () => {
        return axios.get(Links.hostel.getRoles)
    },

    createApprovals: async (data) => {
        return axios.post(Links.hostel.approvals, data)
    },
    updateApprovals: async (data) => {
        return axios.post(Links.hostel.approvals + "/" + data.id, data)
    },
    approvals: async (page, size) => {
        return axios.get(Links.hostel.approvals, {
            params: {
                page, size
            }
        })
    },
    deleteApprovals: async (id) => {
        return axios.delete(Links.hostel.approvals + "/" + id)
    },

    createUser: async (data) => {
        return axios.post(Links.hostel.users, data)
    },
    users: async (page, size) => {
        return axios.get(Links.hostel.users, {
            params: {
                page,
                size
            }
        })
    },
    deleteUser: async (id) => {
        return axios.delete(Links.hostel.approvals + "/" + id)
    },

    updateUser: async (data) => {
        return axios.post(Links.hostel.approvals + "/" + data.id, data)
    },

    hostelUsers(){
        return axios.get(Links.hostel.hostelUsers)
    },

    checkIn(allocationId){
        return axios.post(Links.hostel.checkIn + "/" + allocationId)
    },

    checkOut(allocationId){
        return axios.post(Links.hostel.checkOut + "/" + allocationId)
    },
    userPrivilege: async (userId) => {
        return axios.get(Links.hostel.userPrivilege, {
            params: {
                user: userId
            }
        })
    },

    // get interest fees
    getInterestFees: async () => {
        return axios.get(Links.hostel.getInterestFees)
    },

    // updae budget
    updateInterestBudget: async (data) => {
        return axios.post(Links.hostel.updateInterestBudget, data)
    }
}


export default Hostel
