export default {
    Lecturer: 'LECTURER',
    Hod: 'HOD',
    Dean: 'FACULTY_DEAN',
    DepartmentOfficer: 'DEPARTMENTAL_EXAM_OFFICER',
    FacultyOfficer: 'FACULTY_EXAM_OFFICER',
    Student: 'STUDENT',
    Applicant: 'APPLICANT',
    VC: 'VC',
    Admin: 'ADMIN',
    SuperAdmin: 'SUPER_ADMIN',
    Senate: 'SENATE',
    FacultyExamBoard: 'FACULTY_EXAM_BOARD_MEMBER',
    DVCAcademics: 'DVC_ACADEMIC',
    DVCAdmin: 'DVC_ADMIN',
    Bursar: 'BURSAR',
    Registrar: 'REGISTRAR',
    AcademicSecretary: 'ACADEMIC_SECRETARY',
    StudentAffair: 'STUDENT_AFFAIR',
    AdmissionOfficer: 'ADMISSION_OFFICER',
    HostelOfficer: 'HOSTEL_OFFICER',
    HostelAdministrator: 'HOSTEL_ADMINISTRATOR',
    PassportOfficer: 'PASSPORT_UPDATE',
    BiometricOfficer: 'BIOMETRIC_OFFICER',
    MedicalOfficer: 'MEDICAL_OFFICER',
    SecurityOfficer: 'SECURITY_OFFICER',
    StudentManagement: 'STUDENT_MANAGEMENT',
    StaffManagement: 'STAFF_MANAGEMENT',
    Clearance: 'CLEARANCE',
    ScreeningVerificationOfficer: 'SCREENING_VERIFICATION',
    ChangedOfProgramme: 'CHANGE_OF_PROGRAMME',
    ChangedOfProgrammeApplicant: 'CHANGE_OF_PROGRAMME_APPLICANT',
    DAP: 'DAP',
    StudentAccount: 'STUDENT_ACCOUNT',
    SenateSubCommittee: 'SENATE_SUB_COMMITTEE',
    Clearer: "CLEARER",
    SupportOfficer: "SUPPORT_OFFICER",
    DataCorrectionVerification: "DATA_CORRECTION_VERIFICATION",
    ResultAdmin: "RESULT_ADMIN",
    AnnouncementOfficer: "ANNOUNCEMENT_OFFICER",
    PaymentVerificationOfficer: 'PAYMENT_VERIFICATION_ADMIN',
    StudentManagementAdministrative: 'STUDENT_MANAGEMENT_ADMINISTRATION',
    TranscriptOfficer: 'TRANSCRIPT_OFFICER',
    StatsOfficer: 'STATS_OFFICER',
    HeadOfSenate: 'HEAD_OF_SENATE',

    contains: (roles, role) => {
        return roles.includes(role)
    },

    deepContains: (mRoles, uRoles) => {
        for (let i = 0; i < uRoles.length; i++) {
            for (let j = 0; j < mRoles.length; j++){
                if(uRoles[i] === mRoles[j])
                    return true
            }
        }
        return false
    }
}
