import axios from "axios";
import Links from "shared/constants/Links";
const WaiveReason = {
    getAll: async () => {
        return axios.get(Links.waiveReason.all)
    },

    create: async (data) => {
        return axios.post(Links.waiveReason.create, data)
    },

    update: async (id, data) => {
        return axios.put(Links.waiveReason.update + '/' + id, data)
    },

    delete: async (id) => {
        return axios.delete(Links.waiveReason.delete + '/' + id)
    }
}

export default WaiveReason