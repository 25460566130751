<template>
  <v-container class="pa-0 ma-0" fluid style="height: 100vh; width: 100%">
    <div class="d-flex flex-column-reverse flex-md-row-reverse flex-no-wrap pa-0" style="height: 100%; width: 100%; overflow-y: hidden;">

      <div class="flex-grow-1 px-md-6 col-md-6 col-12 my-auto" style="width: 100%;">
        <v-sheet elevation="0" :height="'auto'" class="px-4 mt-auto" rounded>
            <v-row justify="center" >
              <v-col cols="12" md="10">
                <component
                    :is="currentPage"
                    @close="() => {currentPage = loginPage; currentPageValue = 'login'}"

                />


                <div class="mt-2" style="display: flex; justify-content: space-between;">
                  <div
                      v-for="(item, index) in computedComponents"
                      :key="index"
                      :style="[{fontSize: '0.8rem'}]"
                  >
                    <div class="font-weight-bold" :style="{listStyle: 'none', fontWeight: 'bold', letterSpacing: 0}">
                      <a
                          :style="{fontWeight: 'bold', color: $vuetify.theme.currentTheme.primary}" class=""
                          @click="() => {currentPage = item.children; currentPageValue = item.value; show=false}">
                        {{ item.title }}
                      </a>
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>


        </v-sheet>

      </div>


      <!-- LEFT HAND SIDE -->
      <div class="flex-grow-1 col-12 col-md-6 ma-0 pa-0 white--text " style="" v-if="isMediumAndUp">
        <div class="pa-0 " style="height: 100vh" :style="{background: $vuetify.theme.currentTheme.primaryLight}">
          <div class="d-flex flex-column align-center justify-space-between pa-10" style="height: 100%; ">
            <div class="d-flex flex-row align-center flex-grow-1" >
              <div>
                <v-img
                  aspect-ratio="1"
                  width="70px"
                  style="mix-blend-mode: darken"
                  src="../../../resources/fpn/logo.svg"/>
              </div>
              <div class="ml-4" style="font-size: 1.7em; text-transform: uppercase; color:#029241; font-weight: bold !important;">
                FEDERAL POLYTECHNIC NASARAWA
              </div>
            </div>

            <img
                src="../../../resources/fpn/fpn_illustration.svg"
                width="60%"
                height="60%"
                class="side-img flex-grow-1"
                alt="fpn_illustration"
            />
            <v-spacer></v-spacer>
          </div>
        </div>
      </div>
    </div>
    <message-box :show="messageShow" :message="message" :type="messageType"
      v-on:close-message="messageShow = !messageShow"></message-box>
    <loading-dialog :show="loading"></loading-dialog>
    <announcement :show="show" :announcements="announcements" />
  </v-container>
</template>



<script>
import Roles from "@/shared/Roles";
import LoginUtil from "../../LoginUtil";
import ErrorResolver from "@/shared/ErrorResolver";
import Login from "../../fpn/components/Login"
import Api from "@/main/api";
import Links from "@/shared/constants/Links";
import Defaults from "@/shared/Defaults";
import FpnRegistration from "./FpnRegistration.vue";
import ForgotPassword from '../../fpn/components/ForgotPassword';
import Loginv2 from '../../fpn/components/Loginv2.vue'
import LoadingDialog from "@ui/components/LoadingDialog.vue";

import MessageBox from "@ui/components/MessageBox";
import Announcement from "@ui/components/Announcement.vue";


export default {
  name: 'Auth',
  components: {
    Announcement,
    ForgotPassword,
    Login,
    LoadingDialog,
    MessageBox,
    FpnRegistration,
    Loginv2
  },
  data: () => ({
    isLoading: false,
    loading: false,
    hasError: false,
    errorMsg: "",
    mfaMsg: "",
    mfaSent: false,
    messageType: '',
    message: '',
    messageShow: false,
    usingOldReg: false,
    newRegMessage: '',
    changeCourseMessage: '',
    authMode: 0,
    reader: null,
    loginPage: null,
    deviceConnected: false,
    showForgotPassword: false,
    showApplication: true,
    showPassword: false,
    announcements: [],
    show: false,
    logo: '',
    stateGlobal: {},
    formData: {
      username: "",
      password: ""
    },

    mfa: {
      userId: '',
      code: '',
      recovery: null
    },
    mfas: [],
    tobeRender: [],
    usingRecovery: false,
    isMfa: false, // for multi factor auth
    currentPage: 'Login',
    currentPageValue: 'reset',
    baseComponents: [
      { id: 11, title: 'Login', showIn: ['nnn'], value: 'nnn', children: Login},
      { id: 1, title: 'Forgot password', showIn: ['login', 'fpnRegistration'], value: 'forgot', children: ForgotPassword},
      { id: 2, title: 'New Application', showIn: ['login', 'applicant'], value: 'fpnRegistration', children: FpnRegistration},
      { id: 3, title: 'Applicant Login', showIn: ['fpnRegistration'], value: 'applicant', children: Loginv2},
      { id: 4, title: 'Student Login', showIn: ['fpnRegistration', 'applicant'], value: 'login', children: Login}
    ]
  }),

  methods: {
    login() {
      if (this.formData.password === undefined || this.formData.password === '' || this.formData.username === undefined || this.formData.username === '')
        return
      // start loading
      this.isLoading = true;
      this.usingOldReg = false;

      Api.auth.login(this.formData).then(res => {

        this.hasError = false;
        // if using old reg no.
        if (res.data.message){ // change of programme
          this.usingOldReg = true;
          this.newRegMessage = "Use the username: " + res.data.message + " as you new username";
        } else if (res.data.old) { //
          this.usingOldReg = true;
          this.newRegMessage = "Login to the institutional email account: " + res.data.userId + ' with the password: 12345678 for your student ID'
        }
        // if multi factor enabled
        else if (res.data.mfa) {
          this.isMfa = true
          this.mfa.userId = res.data.userId
        } else {
          // save to cookie
          LoginUtil.setAccessToken(res.data.jwt);
          LoginUtil.setRefreshToken(res.data.refreshToken);
          if (Roles.contains(res.data.roles, Roles.Admin)) {
            window.location.href = '/main';
          } else if (Roles.contains(res.data.roles, Roles.Student) || Roles.contains(res.data.roles, Roles.Applicant)) {
            window.location.href = '/student';
          } else {
            window.location.href = '/officer';
          }
        }

      }).catch(err => {
        this.hasError = true
        ErrorResolver.checkErrors(this, err)
      }).finally(() => {
        this.isLoading = false;
      })
    },

    fetchGlobal() {
      Api.global.fetch().then((res) => {
        this.stateGlobal = res.data;
        this.logo = Defaults.api + Links.global.logo;
      }).catch((err) => ErrorResolver.checkErrors(this, err))
        .finally(() => this.loading = false)
      },

    loadAnnouncements(){
      Api.annoucement.fetchPublic()
          .then(res => {
            this.announcements = res.data
            this.show = (this.announcements.length > 0)
          })
    }
  },

  computed: {
    isMediumAndUp: function () {
      return this.$vuetify.breakpoint.mdAndUp
    },

    isMobile:function() {
      return this.$vuetify.breakpoint.mobile
    },

    computedComponents: function(){
      return this.baseComponents.filter((component) => {
        return component.showIn.includes(this.currentPageValue)
      })
    },

    currentComponent: function() {
      return this.currentPage = this.currentRoute;
    },

    hiddenLink: function() {
      return this.currentPageValue === 'Application' ? 'none' : 'flex';
    },
  },

  watch: {
  },

  created() {
    this.loginPage = Login
    this.loadAnnouncements()
  },


  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    let signup = urlParams.get('Application');
    if(signup){
      this.currentPage = this.baseComponents[1].children;
      this.currentPageValue = this.baseComponents[1].value;
    }
    else{
      this.currentPageValue = 'login';
    }

    this.fetchGlobal();
  }
}
</script>


<style scoped>
.side-img {
  margin-left: auto;
  margin-right: auto;
}



</style>
